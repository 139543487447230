import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import SplitText from '../../../utils/SplitText/SplitText';

import { Container } from 'components/container/Container';
import { RichText } from 'components/contentful/rich-text/RichText';
import { Button } from 'components/button/Button';
import { PrismicRichText } from 'types/prismicRichText';

import { scroll } from 'constants/Scroll'

import s from './BillboardText.scss';

export const BillboardTextContentful = ({ billboardText, lessSpacing, style }: any) => {

  const elRef = useRef<HTMLDivElement>(null);
  let tl:GSAPTimeline;
  const text = billboardText.text;
  const title = billboardText.title;
  const cta = billboardText.cta;
  useEffect( ():any => {

    // Set timeout to make sure the title lines are split correctly
    setTimeout(() => {
      if (elRef.current) {
        const title = elRef.current.querySelector('h3');
        let titleLines = new SplitText(title, { type: 'lines'}).lines;
        titleLines = new SplitText(titleLines, { type: 'lines' }).lines;

        tl = gsap.timeline({
          id: 'gsap-id-billboard-text',
          scrollTrigger: {
            scroller: scroll.container,
            trigger: elRef.current,
          }
        });

        tl
          .addLabel('start')
          .set(titleLines, { yPercent: 100 })
          .set(elRef.current, { autoAlpha: 1 })

          .to(titleLines, { duration: 1.5, stagger: 0.1, ease: 'Power3.easeInOut', yPercent: 0 }, 'start');
      }
    }, 1500);

    return () => tl && tl.kill()
  })

  return (
    <div ref={elRef} className={s('billboardText', {lessSpacing})} style={style}>
      <Container>
        {title && <h2 className={`u-visually-hidden`}>{title}</h2>}

          <div className={s.billboardText__row}>
            <div className={s.billboardText__col} aria-hidden="true">
              {title && <h2 className={s('title')}>{title}</h2>}
              {text && <RichText richText={text}/>}
              {cta && <div className={s.billboard__ctaContainer}>
            <Button label={cta.label} href={cta.href} ></Button></div>}
            </div>
          </div>
      </Container>
    </div>
  );

}